import create from "./assets/img/create.png";
import transfer from "./assets/img/transfer.png";
import manage from "./assets/img/manage.png";
import sphere from "./assets/img/sphere.png";
import bag from "./assets/img/bag.png";
import logo from "./assets/img/logo.png";
import logoFooter from "./assets/img/logo-footer.png";
import bgTopLeft from "./assets/img/top-left.png";
import bgTopRight from "./assets/img/top-right.png";
import btc from "./assets/img/btc.png";
import intro1 from "./assets/img/intro-1.png";
import intro2 from "./assets/img/intro-2.png";
import sol1 from "./assets/img/sol.png";
import sol2 from "./assets/img/sol-2.png";
import ether from "./assets/img/etherium.png";
import left2 from "./assets/img/left-2.png";
import left3 from "./assets/img/left-3.png";
import looper from "./assets/img/looper.png";
import right2 from "./assets/img/right-2.png";
import right3 from "./assets/img/right-3.png";
import glass from "./assets/img/glass.png";
import footer from "./assets/img/left-bottom.png";

import AOS from "aos";
import "aos/dist/aos.css";

import "./App.css";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 800,
    });
    AOS.refresh();
  }, []);
  return (
    <div className="App">
      <header className="relative py-10 flex flex-col lg:min-h-screen 2xl:min-h-[80vh] bg-header">
        <div className="absolute top-0 max-w-lg 2xl:max-w-3xl">
          <img src={bgTopLeft} alt="" />
        </div>
        <div className="absolute top-0 right-0 max-w-lg">
          <img src={bgTopRight} alt="" />
        </div>
        <div className="absolute top-36 left-10 w-36 xl:w-52 2xl:w-64">
          <img src={btc} alt="" />
        </div>
        <div className="absolute top-1/2 left-10 w-32 xl:w-40 2xl:w-60 hidden lg:block">
          <img src={intro1} alt="" />
        </div>
        <div className="absolute bottom-20 left-40 w-32 xl:bottom-0 2xl:left-52 xl:w-56 2xl:w-60 2xl:bottom-10">
          <img src={sol1} alt="" />
        </div>
        <div className="absolute top-1/4 right-40 w-24 2xl:w-48 hidden lg:block">
          <img src={sol2} alt="" />
        </div>
        <div className="absolute top-2/3 right-44 w-40 xl:w-56 2xl:w-80 2xl:right-80">
          <img src={ether} alt="" />
        </div>
        <div className="absolute top-1/2 right-0 w-32 2xl:w-52 hidden lg:block">
          <img src={intro2} alt="" />
        </div>
        <div className="absolute top-1/3 left-1/3 w-32 xl:w-50 2xl:w-60 opacity-50">
          <img src={glass} alt="" />
        </div>
        <div className="relative container mx-auto lg:px-5 flex flex-col gap-5 lg:flex-row justify-between items-center">
          <div className="max-w-[220px]">
            <img src={logo} alt="logo" />
          </div>
          <ul className="flex items-center gap-10 font-semibold">
            <li>
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className="transition-all hover:text-purple"
              >
                Chart
              </a>
            </li>
            <li>
              <a
                href="https://t.me/SolPocketBotTG"
                target="_blank"
                rel="noopener noreferrer"
                className="transition-all hover:text-purple"
              >
                Telegram
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/SolPocketBot"
                target="_blank"
                rel="noopener noreferrer"
                className="transition-all hover:text-purple"
              >
                Twitter
              </a>
            </li>
            <li>
              <a
                className="transition-all hover:text-purple"
                href="https://solpocketbot.gitbook.io/docs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Docs
              </a>
            </li>
          </ul>
          <a
            href="https://t.me/SolPocketBot"
            target="_blank"
            rel="noopener noreferrer"
            className="font-semibold bg-gradient py-3 px-6 rounded-xl transition-all hover:opacity-75"
          >
            Telegram Bot
          </a>
        </div>

        <div className="flex-1 w-full flex flex-col justify-center px-4 relative max-w-4xl xl:max-w-5xl 2xl:max-w-7xl mx-auto pb-32 text-center py-32">
          <h1 className="text-center text-gradient font-black text-3xl lg:text-6xl xl:text-7xl 2xl:text-9xl">
            We make Solana more Clear and Simple
          </h1>
          <p className="mt-5 ca w-full">CA: 0x000000000000000000000</p>
          <a
            href="#"
            target="_blank"
            rel="noopener noreferrer"
            className="mt-16 mx-auto w-fit bg-gradient py-3 px-6 rounded-xl inline-flex transition-all hover:opacity-75 font-semibold"
          >
            Buy $SPBOT
          </a>
        </div>
      </header>

      <main>
        <section className="pb-20">
          <div className="container mx-auto px-4 lg:px-5 grid grid-cols-1 lg:grid-cols-3 gap-5">
            <div
              className="text-center bg-[#1A1B23] rounded-2xl py-8 px-6"
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-delay="500"
            >
              <div className="w-20 h-20 mx-auto">
                <img src={create} alt="" />
              </div>
              <h3 className="font-extrabold mt-5 mb-4 text-2xl">Create</h3>
              <p className="text-text max-w-xs mx-auto">
                Easily create a series of wallets on Solana by using Telegram
                bot without needing to install any other extensions
              </p>
              <a
                href="https://t.me/SolPocketBot"
                target="_blank"
                rel="noopener noreferrer"
                className="flex text-purple justify-center items-center font-semibold mt-8"
              >
                <span>Get Started</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12.9091 7L18 12M18 12L12.9091 17M18 12L6 12"
                    stroke="#B982FF"
                    stroke-width="2"
                    stroke-linecap="square"
                  />
                </svg>
              </a>
            </div>
            <div
              className="text-center bg-[#1A1B23] rounded-2xl py-8 px-6"
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-delay="400"
            >
              <div className="w-20 h-20 mx-auto">
                <img src={transfer} alt="" />
              </div>
              <h3 className="font-extrabold mt-5 mb-4 text-2xl">Transfer</h3>
              <p className="text-text max-w-xs mx-auto">
                Easily transfer, multi-send your token to many wallets with one
                touch without having to go through third-party software
              </p>
              <a className="flex text-purple justify-center items-center font-semibold mt-8">
                <span>Get Started</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12.9091 7L18 12M18 12L12.9091 17M18 12L6 12"
                    stroke="#B982FF"
                    stroke-width="2"
                    stroke-linecap="square"
                  />
                </svg>
              </a>
            </div>
            <div
              className="text-center bg-[#1A1B23] rounded-2xl py-8 px-6"
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-delay="300"
            >
              <div className="w-20 h-20 mx-auto">
                <img src={manage} alt="" />
              </div>
              <h3 className="font-extrabold mt-5 mb-4 text-2xl">Manage</h3>
              <p className="text-text max-w-xs mx-auto">
                Easily manage and track your wallet. Check any wallet or smart
                contract through available professional features
              </p>
              <a className="flex text-purple justify-center items-center font-semibold mt-8">
                <span>Get Started</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12.9091 7L18 12M18 12L12.9091 17M18 12L6 12"
                    stroke="#B982FF"
                    stroke-width="2"
                    stroke-linecap="square"
                  />
                </svg>
              </a>
            </div>
          </div>
        </section>

        <section className="py-20 lg:py-32 relative" data-aos="fade-up">
          <div className="absolute right-0 -top-1/2 max-w-xl">
            <img src={looper} alt="" />
          </div>
          <div className="relative max-w-3xl mx-auto lg:px-5 text-center">
            <h2 className="text-3xl lg:text-5xl font-black leading-tight">
              The decentralized user data management platform
            </h2>
            <p className="max-w-xl mx-auto text-text mt-5">
              User data is stored on-chain. No one can access and steal it, even
              the developer team or hackers
            </p>
            <a className="mt-8 bg-gradient py-3 px-6 rounded-xl mt-16 inline-flex transition-all hover:opacity-75">
              Get Started
            </a>
          </div>
        </section>

        <div className="py-20 lg:py-32 relative">
          <div className="absolute left-0 max-w-lg -top-32">
            <img src={left2} alt="" />
          </div>
          <div className="relative z-10 container mx-auto px-4 lg:px-5 flex flex-col lg:flex-row justify-between gap-10">
            <div
              data-aos="fade-right"
              className="max-w-[250px] lg:max-w-xs mx-auto lg:mx-0"
            >
              <img src={sphere} alt="" />
            </div>
            <div
              data-aos="fade-left"
              className="max-w-xl text-center lg:text-left"
            >
              <h2 className="text-3xl lg:text-5xl font-black leading-tight">
                Smart Contracts Auditability Checking
              </h2>
              <p className="mx-auto text-text mt-5">
                A special feature allows users to check whether a project's
                smart contracts have been audited or not. Minimize risks for
                users
              </p>
              <a className="relative mt-8 bg-transparent border-[1px] border-white py-3 px-6 rounded-xl mt-16 inline-flex transition-all hover:opacity-75">
                Audit Checker
              </a>
            </div>
          </div>
        </div>

        <div className="py-20 lg:py-32 relative" data-aos="fade-up">
          <div className="absolute right-0 -top-1/2">
            <img src={right2} alt="" />
          </div>
          <div className="absolute left-0 -bottom-1/3 max-w-md">
            <img src={left3} alt="" />
          </div>
          <div className="relative max-w-3xl mx-auto px-4 lg:px-5 text-center">
            <h2 className="text-3xl lg:text-5xl font-black leading-tight">
              Easily to trade with Trading Monitor Bot
            </h2>
            <p className="max-w-xl mx-auto text-text mt-5">
              With the ability to track price fluctuations in real-time, Sol
              Pocket Bot makes your trading easier than ever
            </p>
            <a
              href="https://t.me/SolPocketBot"
              target="_blank"
              rel="noopener noreferrer"
              className="flex text-purple justify-center items-center font-semibold mt-8"
            >
              <span>Learn More</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12.9091 7L18 12M18 12L12.9091 17M18 12L6 12"
                  stroke="#B982FF"
                  stroke-width="2"
                  stroke-linecap="square"
                />
              </svg>
            </a>
          </div>
          <div className="relative container mx-auto px-4 lg:px-5 mt-12">
            <div className="bg-[#1A1B23] px-4 lg:px-8 py-2 rounded-2xl font-bold text-sm lg:text-base">
              <div className="flex justify-between gap-2 lg:gap-4 items-center py-2 border-b-[1px] border-b-[#2B2C3B]">
                <span className="w-32 hidden lg:block">Bitcoin</span>
                <span className="w-20 text-purple">BTC</span>
                <span className="w-24 lg:w-36">$56,290.30</span>
                <span className="w-20 text-[#0DBB7C]">+1.68%</span>
                <span className="max-w-[200px] hidden lg:block">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="56"
                    viewBox="0 0 214 56"
                    fill="none"
                  >
                    <path
                      d="M5 37H35.5L48.5 41.5L64.3279 34.2968L82.2857 52L113.5 23L126.5 27.5L140.67 15.8968L162 41.5L175 30.3484L193.5 33.6L213 18.7871"
                      stroke="url(#paint0_linear_0_147)"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_0_147"
                        x1="173.235"
                        y1="19.746"
                        x2="164.602"
                        y2="69.4823"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#18C8FF" />
                        <stop offset="1" stop-color="#933FFE" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
                <a className="flex gap-1 items-center w-fit lg:w-40">
                  <span>Trade Now</span>
                  <span className="hidden lg:block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12.9091 7L18 12M18 12L12.9091 17M18 12L6 12"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="square"
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <div className="flex justify-between gap-2 lg:gap-4  items-center py-2 border-b-[1px] border-b-[#2B2C3B]">
                <span className="w-32 hidden lg:block">Ethereum</span>
                <span className="w-20 text-purple">ETH</span>
                <span className="w-24 lg:w-36">$4,284.81</span>
                <span className="w-20 text-[#0DBB7C]">+4.36%</span>
                <span className="max-w-[200px] hidden lg:block">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="56"
                    viewBox="0 0 214 56"
                    fill="none"
                  >
                    <path
                      d="M5 15.8968L35.4169 30.3484L51 26L64.3279 34.2968L83 9.5L98 3L124 34.2968L135.5 21L156 9.5L175 30.3484L193 9.5L213 18.7871"
                      stroke="url(#paint0_linear_0_162)"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_0_162"
                        x1="173.235"
                        y1="6.33679"
                        x2="166.701"
                        y2="49.7673"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#18C8FF" />
                        <stop offset="1" stop-color="#933FFE" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
                <a className="flex gap-1 items-center w-fit lg:w-40">
                  <span>Trade Now</span>
                  <span className="hidden lg:block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12.9091 7L18 12M18 12L12.9091 17M18 12L6 12"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="square"
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <div className="flex justify-between items-center py-2 border-b-[1px] border-b-[#2B2C3B]">
                <span className="w-32 hidden lg:block">Solana</span>
                <span className="w-20 text-purple">SOL</span>
                <span className="w-24 lg:w-36">$58.88</span>
                <span className="w-20 text-[#0DBB7C]">+3.43%</span>
                <span className="max-w-[200px] hidden lg:block">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="56"
                    viewBox="0 0 217 56"
                    fill="none"
                  >
                    <path
                      d="M1.5 32L38.4169 25.6516L51.8642 7.97418L67.3279 21.7032L85.2857 3.99998L116.5 37.2129L132.197 22.4L143.67 40.1032L163 3.99998L178 25.6516L196.5 22.4L216 37.2129"
                      stroke="url(#paint0_linear_0_177)"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_0_177"
                        x1="174.993"
                        y1="36.254"
                        x2="166.607"
                        y2="-13.5693"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#18C8FF" />
                        <stop offset="1" stop-color="#933FFE" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
                <a className="flex gap-1 items-center w-fit lg:w-40">
                  <span>Trade Now</span>
                  <span className="hidden lg:block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12.9091 7L18 12M18 12L12.9091 17M18 12L6 12"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="square"
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <div className="flex justify-between items-center py-2 border-b-[1px] border-b-[#2B2C3B]">
                <span className="w-32 hidden lg:block">Wax</span>
                <span className="w-20 text-purple">WAXP</span>
                <span className="w-24 lg:w-36">$0.97</span>
                <span className="w-20 text-[#FF8282]">-2.62%</span>
                <span className="max-w-[200px] hidden lg:block">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="56"
                    viewBox="0 0 214 56"
                    fill="none"
                  >
                    <path
                      d="M5 37H35.5L48.5 41.5L64.3279 34.2968L82.2857 52L113.5 23L126.5 27.5L140.67 15.8968L162 41.5L175 30.3484L193.5 33.6L213 18.7871"
                      stroke="url(#paint0_linear_0_147)"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_0_147"
                        x1="173.235"
                        y1="19.746"
                        x2="164.602"
                        y2="69.4823"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#18C8FF" />
                        <stop offset="1" stop-color="#933FFE" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
                <a className="flex gap-1 items-center w-fit lg:w-40">
                  <span>Trade Now</span>
                  <span className="hidden lg:block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12.9091 7L18 12M18 12L12.9091 17M18 12L6 12"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="square"
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <div className="flex justify-between items-center py-2">
                <span className="w-32 hidden lg:block">Polkadot</span>
                <span className="w-20 text-purple">DOT</span>
                <span className="w-24 lg:w-36">$42.22</span>
                <span className="w-20 text-[#0DBB7C]">+7.56%</span>
                <span className="max-w-[200px] hidden lg:block">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="56"
                    viewBox="0 0 214 56"
                    fill="none"
                  >
                    <path
                      d="M5 15.8968L35.4169 30.3484L51 26L64.3279 34.2968L83 9.5L98 3L124 34.2968L135.5 21L156 9.5L175 30.3484L193 9.5L213 18.7871"
                      stroke="url(#paint0_linear_0_207)"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_0_207"
                        x1="173.235"
                        y1="6.33679"
                        x2="166.701"
                        y2="49.7673"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#18C8FF" />
                        <stop offset="1" stop-color="#933FFE" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
                <a className="flex gap-1 items-center w-fit lg:w-40">
                  <span>Trade Now</span>
                  <span className="hidden lg:block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12.9091 7L18 12M18 12L12.9091 17M18 12L6 12"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="square"
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="py-20 lg:py-32 relative">
          <div className="absolute hidden lg:block right-0 -top-1/3">
            <img src={right3} alt="" />
          </div>
          <div className="relative container mx-auto px-4 lg:px-5 flex flex-col-reverse justify-between lg:flex-row gap-10">
            <div
              className="max-w-xl text-center lg:text-left"
              data-aos="fade-right"
            >
              <h2 className="text-3xl lg:text-5xl font-black leading-tight">
                Take your first step into safe, secure crypto investing
              </h2>
              <p className="mx-auto text-text mt-5">
                With professional decentralized features that provide maximum
                support for all types of users from basic to advanced in the
                same set of tools to help you operate in the Solana chain space
                more easily and safely
              </p>
              <a
                href="https://t.me/SolPocketBot"
                target="_blank"
                rel="noopener noreferrer"
                className="mt-8 bg-gradient py-3 px-6 rounded-xl inline-flex transition-all hover:opacity-75"
              >
                Open BOT
              </a>
            </div>
            <div
              className="max-w-[250px] mx-auto lg:mx-0 lg:max-w-xs"
              data-aos="fade-left"
            >
              <img src={bag} alt="" />
            </div>
          </div>
        </div>

        <div data-aos="fade-up" className="py-20 lg:py-32">
          <div className="max-w-3xl mx-auto lg:px-5 text-center">
            <h2 className="text-5xl font-black leading-tight">
              Receive transmissions
            </h2>
            <p className="mx-auto text-text mt-5">
              Send us at any time, ask me any thing!
            </p>
            <div className="bg-gradient inline-flex h-14 w-80 justify-center items-center rounded-xl mt-8 relative">
              <input
                className="w-[calc(100%-4px)] h-[calc(100%-4px)] pl-5 pr-20 rounded-xl bg-black"
                placeholder="support@solpocketbot.com"
              />
              <span className="absolute top-1/2 -translate-y-1/2 right-5 cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12.9091 7L18 12M18 12L12.9091 17M18 12L6 12"
                    stroke="#565976"
                    stroke-width="2"
                    stroke-linecap="square"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </main>

      <footer className="relative bg-[rgba(11, 11, 15, 0.20)] pt-20 py-10 border-t-[1px] border-[#1A1B23]">
        <div className="absolute bottom-0">
          <img src={footer} alt="" />
        </div>
        <div className="relative container mx-auto px-4 lg:px-5">
          <div className="flex flex-col items-center gap-5 lg:flex-row lg:justify-between">
            <div className="text-white/50 flex flex-col lg:flex-row items-center gap-4">
              <div className="w-16">
                <img src={logoFooter} alt="" />
              </div>
              <p className="max-w-xs text-center lg:text-left">
                The first professional bot to support users on the Solana chain.
                Fastest, safest and most reliable for all types of users
              </p>
            </div>
            <div>
              <span className="text-white/50">
                Privacy Policy and Terms of Service
              </span>
              <div className="flex gap-5 justify-center lg:justify-end mt-5">
                <a
                  href="https://t.me/SolPocketBotTG"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <rect
                      width="28"
                      height="28"
                      rx="14"
                      fill="url(#paint0_linear_2_29)"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.3374 13.8522C10.4187 12.074 13.1402 10.9018 14.5019 10.3354C18.3898 8.71827 19.1977 8.43735 19.7243 8.42808C19.8401 8.42604 20.0991 8.45474 20.2668 8.59085C20.4084 8.70577 20.4474 8.86102 20.466 8.96998C20.4847 9.07895 20.5079 9.32716 20.4895 9.52112C20.2788 11.7348 19.3671 17.107 18.9033 19.5864C18.7071 20.6355 18.3206 20.9873 17.9465 21.0217C17.1335 21.0965 16.5162 20.4844 15.7287 19.9683C14.4966 19.1605 13.8005 18.6577 12.6044 17.8696C11.2222 16.9587 12.1182 16.4581 12.906 15.6399C13.1121 15.4258 16.6942 12.1676 16.7636 11.872C16.7722 11.835 16.7803 11.6972 16.6984 11.6245C16.6166 11.5517 16.4957 11.5766 16.4086 11.5964C16.285 11.6244 14.3165 12.9255 10.5033 15.4995C9.94454 15.8832 9.43846 16.0701 8.98503 16.0604C8.48516 16.0496 7.5236 15.7777 6.80879 15.5454C5.93204 15.2604 5.23521 15.1097 5.29589 14.6257C5.32749 14.3736 5.67466 14.1157 6.3374 13.8522Z"
                      fill="white"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2_29"
                        x1="14"
                        y1="0"
                        x2="14"
                        y2="27.7923"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#2AABEE" />
                        <stop offset="1" stop-color="#229ED9" />
                      </linearGradient>
                    </defs>
                  </svg>
                </a>
                <a
                  href="https://twitter.com/SolPocketBot"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <rect width="28" height="28" rx="14" fill="#1D9BF0" />
                    <path
                      d="M11.438 21.3849C17.647 21.3849 21.042 16.2399 21.042 11.7809C21.042 11.6339 21.042 11.4869 21.035 11.3469C21.693 10.8709 22.267 10.2759 22.722 9.59689C22.12 9.86289 21.469 10.0449 20.783 10.1289C21.483 9.70889 22.015 9.05089 22.267 8.25989C21.616 8.64489 20.895 8.92489 20.125 9.07889C19.509 8.42089 18.634 8.01489 17.661 8.01489C15.799 8.01489 14.287 9.52689 14.287 11.3889C14.287 11.6549 14.315 11.9139 14.378 12.1589C11.571 12.0189 9.08599 10.6749 7.41999 8.63089C7.13299 9.12789 6.96499 9.70889 6.96499 10.3249C6.96499 11.4939 7.55999 12.5299 8.46999 13.1319C7.91699 13.1179 7.39899 12.9639 6.94399 12.7119C6.94399 12.7259 6.94399 12.7399 6.94399 12.7539C6.94399 14.3919 8.10599 15.7499 9.65299 16.0649C9.37299 16.1419 9.07199 16.1839 8.76399 16.1839C8.54699 16.1839 8.33699 16.1629 8.12699 16.1209C8.55399 17.4649 9.79999 18.4379 11.277 18.4659C10.122 19.3689 8.66599 19.9079 7.08399 19.9079C6.81099 19.9079 6.54499 19.8939 6.27899 19.8589C7.75599 20.8249 9.53399 21.3849 11.438 21.3849Z"
                      fill="white"
                    />
                  </svg>
                </a>
                <a>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <rect width="28" height="28" rx="14" fill="#5865F2" />
                    <path
                      d="M20.7922 7.57662C19.543 6.98575 18.2034 6.55043 16.8027 6.30109C16.7772 6.29628 16.7517 6.30831 16.7386 6.33236C16.5663 6.64823 16.3755 7.06031 16.2418 7.3842C14.7353 7.15171 13.2366 7.15171 11.761 7.3842C11.6273 7.05311 11.4295 6.64823 11.2565 6.33236C11.2433 6.30911 11.2179 6.29709 11.1924 6.30109C9.79247 6.54963 8.45285 6.98496 7.20284 7.57662C7.19202 7.58143 7.18275 7.58946 7.17659 7.59987C4.63562 11.513 3.93954 15.33 4.28101 19.0997C4.28255 19.1181 4.2926 19.1358 4.3065 19.147C5.98297 20.4161 7.60691 21.1866 9.20069 21.6972C9.2262 21.7053 9.25322 21.6956 9.26946 21.674C9.64647 21.1433 9.98254 20.5837 10.2707 19.9952C10.2877 19.9607 10.2715 19.9198 10.2367 19.9062C9.70364 19.6978 9.19605 19.4436 8.70779 19.155C8.66917 19.1318 8.66608 19.0748 8.70161 19.0476C8.80436 18.9682 8.90713 18.8856 9.00524 18.8022C9.02299 18.787 9.04773 18.7838 9.0686 18.7934C12.2763 20.3031 15.7489 20.3031 18.9188 18.7934C18.9396 18.783 18.9644 18.7862 18.9829 18.8014C19.081 18.8848 19.1838 18.9682 19.2873 19.0476C19.3228 19.0748 19.3205 19.1318 19.2819 19.155C18.7936 19.4492 18.286 19.6978 17.7522 19.9054C17.7174 19.9191 17.702 19.9607 17.719 19.9952C18.0133 20.5829 18.3494 21.1425 18.7194 21.6732C18.7349 21.6956 18.7627 21.7053 18.7882 21.6972C20.3897 21.1866 22.0137 20.4161 23.6901 19.147C23.7048 19.1358 23.7141 19.1189 23.7156 19.1005C24.1243 14.7424 23.0311 10.9567 20.8177 7.60067C20.8123 7.58946 20.8031 7.58143 20.7922 7.57662ZM10.7497 16.8044C9.78397 16.8044 8.98824 15.8904 8.98824 14.768C8.98824 13.6456 9.76854 12.7317 10.7497 12.7317C11.7386 12.7317 12.5266 13.6536 12.5111 14.768C12.5111 15.8904 11.7308 16.8044 10.7497 16.8044ZM17.2624 16.8044C16.2967 16.8044 15.501 15.8904 15.501 14.768C15.501 13.6456 16.2812 12.7317 17.2624 12.7317C18.2513 12.7317 19.0393 13.6536 19.0239 14.768C19.0239 15.8904 18.2513 16.8044 17.2624 16.8044Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <p className="max-w-xl text-center mx-auto mt-16 text-white/50">
            © 2023 solpocketbot.com - All rights reserved
          </p>
        </div>
      </footer>
    </div>
  );
}

export default App;
